export default [
    {
        path: '/users/mypage',
        name: 'Wine NFT',
        isLoading: false
    },
    {
        path: '/users/mypage/membership',
        name: 'Membership NFT',
        isLoading: false
    },
    {
        path: '/users/mypage/activity',
        name: 'Activity',
        isLoading: true
    },
    {
        path: '/users/mypage/profile',
        name: 'Profile',
        isLoading: false,
        children: [
            {
                path: '/users/mypage/profile/edit',
                name: 'EditProfile',
            }
        ]
    }
]